$(document).on('ready', function(){

    if ($('#posts').length !== 0) {
        $('#posts').isotope({
            // options
            itemSelector: '.card',
            layoutMode: 'fitRows'
        });
    }

});

function selectPosts(posts) {
    let selectedCountry = $(posts).data('categories');
    $('#about-word').show(0);
    $('#country-word').text($(posts).text());
    $('#posts').isotope({
        filter: function() {
            let ids = $(this).data('categories');
            return ids.includes(parseInt(selectedCountry))
        }
    })
}
