let isotopeInstance;
$(document).ready(function(){

    if ($('#country-teasers').length !== 0) {
        isotopeInstance = $('#country-teasers').isotope({
            // options
            itemSelector: '.card',
            layoutMode: 'fitRows'
        });
    }

});


function selectCountry(country) {
    let selectedCountry = $(country).find('option:selected').val();
    $('#country-teasers').isotope({
        filter: function() {
            let id = $(this).data('id');
            if (selectedCountry === '*') {
                return true;
            } else {
                return id == selectedCountry;
            }
        }
    })
    // "getFilteredItemElements" returns an array of all filtered elements. In this case there will only be one such element so it goes straight to item [0]. If there is an item [1] then that means the user went back to displaying eveything so the "card--active" class is removed without adding it to another element
    let activeItem = $(isotopeInstance).isotope('getFilteredItemElements');

    $('.card--active').removeClass('card--active');
    if (!activeItem[1]) {
        $(activeItem[0]).addClass('card--active');
    }
}
