$( document ).ready(function() {

    if ($('#stories').length !== 0) {
        $('#stories').isotope({
            // options
            itemSelector: '.card',
            layoutMode: 'fitRows'
        });
        let storyId = window.location.href.split('?land=');
        if (storyId.length == 2) {
            selectStories(Number(storyId.pop()));
        }
    }

});

function selectStories(country) {
    selectedCountry = country;
    if(!Number.isInteger(country)) {
        selectedCountry = $(country).data('country-id');
    }
    console.log(selectedCountry);
    $('#about-word').show(0);
    $('#country-word').text($(country).text());
    $('#stories').isotope({
        filter: function() {
            let id = $(this).data('country-id');
            return id == selectedCountry;
        }
    })
}
