var slider = tns({
  container: '#slide-container',
  items: 1,
  autoplay:true,
  autoplayButton:false,
  autoplayButtonOutput:false,
  speed: 500,
  autoplayTimeout:5000,
  loop:true,
  prevButton: "#slide-prev",
  nextButton: "#slide-next"
});

//homepage carousel
if ($('#home-carousel-container').length !== 0) {
    var slider_two = tns({
      loop: true,
      rewind: false,
      controls: true,
      container: '#home-carousel-container',
      swipeAngle: false,
      autoplay:true,
      autoplayButton:false,
      autoplayButtonOutput:false,
      autoplayHoverPause:true,
      speed: 500,
      items:1,
      prevButton: "#slide-prev-car",
      nextButton: "#slide-next-car",
      responsive: {
       1024: {
         items: 2,
          controls: true,
          gutter:70,
          loop: true,
          autoplay:true,
          autoplayButton:false,
          autoplayButtonOutput:false,
      	  speed: 500,
          autoplayTimeout:5000,
        }
      }
    });

    var info_two = slider_two.getInfo(),
        current_two = document.querySelector('.currentslides'),
        total_two = document.querySelector('.totalslides');

    	total_two.textContent = info_two.slideCount;
    	current_two.textContent = slider_two.getInfo().displayIndex;

    	slider_two.events.on('transitionEnd', function(info){
    	 total_two.textContent = info_two.slideCount;
    	 current_two.textContent = slider_two.getInfo().displayIndex;
    });
}


// homepage slider tree items text left
if ($('#home-carousel-container-three').length !== 0) {
    var slider_three = tns({
      loop: true,
      rewind: false,
      controls: true,
      container: '#home-carousel-container-three',
      swipeAngle: false,
      autoplay:true,
      nav:true,
      navPosition:top,
      autoplay:true,
      autoplayTimeout:10000,
      autoplayButton:false,
      autoplayButtonOutput:false,
      autoplayHoverPause:true,
      speed: 500,
      items:1,
      prevButton: "#slide-prev-car-three",
      nextButton: "#slide-next-car-three",
      responsive: {
       1024: {
          items: 3,
          gutter: 40,
          autoplay:true,
        }
      }
    });

    var info_three = slider_three.getInfo(),
        current_three = document.querySelector('.currentslides-three'),
        total_three = document.querySelector('.totalslides-three');

    	total_three.textContent = info_three.slideCount;
    	current_three.textContent = slider_three.getInfo().displayIndex;

    	slider_three.events.on('transitionEnd', function(info){
    	 total_three.textContent = info_three.slideCount;
    	 current_three.textContent = slider_three.getInfo().displayIndex;
    });
}



if ($('#home-carousel-container-four').length !== 0) {
    // homepage slider four items text left
    var slider_four = tns({
      loop: true,
      rewind: false,
      controls: true,
      container: '#home-carousel-container-four',
      swipeAngle: false,
      autoplay:true,
      nav:true,
      navPosition:top,
       autoplay:true,
      autoplayTimeout:10000,
      autoplayButton:false,
      autoplayButtonOutput:false,
      autoplayHoverPause:true,
      speed: 500,
      items:1,
      prevButton: "#slide-prev-car-four",
      nextButton: "#slide-next-car-four",
      responsive: {
       1024: {
          items: 4,
          gutter:35,
        }
      }
    });

    var info_four = slider_four.getInfo(),
        current_four = document.querySelector('.currentslides-four'),
        total_four = document.querySelector('.totalslides-four');

    	total_four.textContent = info_four.slideCount;
    	current_four.textContent = slider_four.getInfo().displayIndex;

    	slider_four.events.on('transitionEnd', function(info){
    	 total_four.textContent = info_four.slideCount;
    	 current_four.textContent = slider_four.getInfo().displayIndex;
    });
};
