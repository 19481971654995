$(document).ready(function () {

    // Only fadeIn/fadeOut mega-menu on hover on "desktop" and above
    // Toggle Megamenu
    $('body').on('click', '.site-header__nav > .has-mega-menu', function (e) {
        if ($(window).width() >= 1200) {
            e.preventDefault();
            if (!$(this).hasClass('expanded')) {
                $('.site-header__nav-item.has-mega-menu.expanded').find('.mega-menu').fadeOut("fast");
                $('.site-header__nav-item.has-mega-menu.expanded').removeClass('expanded');
            }
            $(this).children('.mega-menu').toggle("fast");
            $(this).addClass('expanded');

        };

    });
    // Go to href
    $('body').on('click', 'a.item-link:not(.site-header__nav-item-link)', function (e) {
        if ($(window).width() >= 1200) {
            e.stopPropagation();
        };
    });
    // $( '.site-header__nav-item.has-mega-menu' ).click(showMegaMenu,hideMegaMenu);
    // function showMegaMenu() {
    //     if ($(window).width() >= 1200) {
    //         $( this ).children('.mega-menu').fadeIn( "fast" );
    //     };
    // };
    // function hideMegaMenu() {
    //     // Only fadeOut mega-menu on hover on "desktop" and above
    //     if ($(window).width() >= 1200) {
    //         $( this ).children('.mega-menu').fadeOut( "fast" );
    //     };
    // };

    // Mobile menu
    var isActive = false;
    $('.site-header__nav-item.has-mega-menu .link').click(function (e) {
        if ($(window).width() < 1200) {
            e.preventDefault();
            var clickedMenuItem = $(this);
            clickedMenuItem.toggleClass('is-active');
            if (!isActive) {
                clickedMenuItem.siblings('.mega-menu').fadeIn("fast");
                isActive = true;
            } else {
                clickedMenuItem.children('.mega-menu').fadeOut("fast");
                isActive = false;
            }
            $('.site-header__nav-item').toggleClass('hidden');
        }
    });
    $('.menu__back').click(function () {
        if ($(window).width() < 1200) {
            var clickedMenuItem = $(this);
            if (!isActive) {
                $('.mega-menu').fadeIn("fast");
                isActive = true;
            } else {
                $('.mega-menu').fadeOut("fast");
                isActive = false;
            }
            $('.site-header__nav-item.hidden').removeClass('hidden');
        }
    });


    /******************************************************************************
     Mobile menu
    ******************************************************************************/
    $(window).on("resize", function () {
        1200 <= $(window).width() &&
            ($("html").removeClass("mobile-menu--active"), $("body").removeClass("mobile-menu--active"), $(this).parent().siblings(".site-header__nav-item").removeClass("hidden"), $(this).parent().removeClass("submenu-is-active"));
    });
    var navMobileIsActive = !1;
    $("button.hamburger").click(function () {
        $(this).toggleClass("is-active"),
            (navMobileIsActive = navMobileIsActive
                ? ($("html").removeClass("mobile-menu--active"),
                    $("body").removeClass("mobile-menu--active"),
                    $(".site-header__nav:not(.site-header__nav--right)").removeClass("site-header__nav--mobile-visible"),
                    setTimeout(function () {
                        $(".site-header__nav:not(.site-header__nav--right)").removeClass("site-header__nav--mobile-active");
                    }, 300),
                    !1)
                : ($("html").addClass("mobile-menu--active"),
                    $("body").addClass("mobile-menu--active"),
                    $(".site-header__nav:not(.site-header__nav--right)").addClass("site-header__nav--mobile-active"),
                    setTimeout(function () {
                        $(".site-header__nav:not(.site-header__nav--right)").addClass("site-header__nav--mobile-visible");
                    }, 10),
                    !0));
    });
    var backToTopisVisible = !1;
    $(document).ready(function () {
        var a = !1;
        $(".site-header__nav-item.has-mega-menu .link").click(function (e) {
            if ($(window).width() < 1200) {
                e.preventDefault();
                var i = $(this);
                i.toggleClass("is-active"), (a = a ? (i.children(".mega-menu").fadeOut("fast"), !1) : (i.siblings(".mega-menu").fadeIn("fast"), !0)), $(".site-header__nav-item").toggleClass("hidden");
            }
        }),
            $(".menu__back").click(function () {
                if ($(window).width() < 1200) {
                    $(this);
                    (a = a ? ($(".mega-menu").fadeOut("fast"), !1) : ($(".mega-menu").fadeIn("fast"), !0)), $(".site-header__nav-item.hidden").removeClass("hidden");
                }
            });
    });

    /******************************************************************************\
        Sticky header
    \******************************************************************************/
    // var scroll = $(window).scrollTop();
    // if (scroll >= 183) {
    //   $(".site-header").addClass("sticky-header");
    //   $(".site-content").addClass("sticky-header");
    // }
    // $(window).scroll(function() {
    //   scroll = $(window).scrollTop();
    //
    //   if (scroll >= 183) {
    //     $(".site-header").addClass("sticky-header");
    //     $(".site-content").addClass("sticky-header");p
    //      $(".header-slide").addClass("has-sticky");
    //   } else {
    //     $(".site-header").removeClass("sticky-header");
    //     $(".site-content").removeClass("sticky-header");
    //     $(".header-slide").removeClass("has-sticky");
    //  }
    // });


    /******************************************************************************\
       Back to top button
   \******************************************************************************/
    // Get the button:
    let mybutton = document.getElementById("myBtn");
    let nav = document.getElementById('pnav');

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        if (document.body.scrollTop > 550 || document.documentElement.scrollTop > 550) {

            if (mybutton) {
                mybutton.style.display = "block";
            }

            if (nav) {
                nav.classList.add('sticky');
            }

        } else {

            if (mybutton) {
                mybutton.style.display = "none";
            }


            if (nav) {
                nav.classList.remove('sticky');
            }

        }
    }
});
